<template>
  <a-card>
    <div class="nav_box">
      <a-button
        icon="plus"
        type="primary"
        style="margin-right: 10px"
        @click="createTag"
        >创建广播</a-button
      >
    </div>
    <a-table
      :columns="columns"
      :loading="table_loading"
      :pagination="false"
      :rowKey="(record, index) => index"
      :data-source="tableList"
    >
      <div slot="chatrooms" slot-scope="text" class="group_list">
        <span v-for="item in text" :key="item.id"
          ><span v-if="item.chatroom_name"
            >{{ item.chatroom_name }}、</span
          ></span
        >
      </div>
      <div slot="action" slot-scope="text, record">
        <a-button type="link" @click="goSendMessage(text)">再发一次</a-button>
        <a-divider type="vertical" />
        <a-button type="link" @click="gotoEditGroup(record.chatrooms)"
          >编辑</a-button
        >
        <a-divider type="vertical" />
        <a-button
          type="link"
          style="color: red"
          @click="deleteGroupRecord(text)"
          :loading="activeId == text && delBtnLoading"
          >删除记录</a-button
        >
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.page_size"
      :total="pagination.total"
      @onChange="onChange"
      @onShowSizeChange="onChange"
    >
    </base-pagination>
  </a-card>
</template>
<script>
const columns = [
  {
    title: "群数量",
    dataIndex: "contacts_count",
    align: "center",
  },
  {
    title: "群详情",
    dataIndex: "chatrooms",
    scopedSlots: { customRender: "chatrooms" },
    align: "center",
  },
  {
    title: "最近发送时间",
    dataIndex: "last_send_time",
    align: "center",
  },
  {
    title: "操作",
    dataIndex: "id",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];
export default {
  data() {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
      },
      tag_name: "",
      table_loading: false,
      columns,
      tableList: [],
      pagination: {
        current: 1,
        page_size: 10,
        total: 0,
      },
      delBtnLoading: false,
      recordCurrent: 1,
      activeId: "",
    };
  },
  mounted() {
    this.getTableList(1, 10);
  },
  methods: {
    getTableList(current, page_size) {
      let params = {
        page_num: current,
        page_size,
      };
      this.table_loading = true;
      this.$api.core.GroupChat.getGroupMessage(params)
        .then((res) => {
          if (res.code == 200) {
            this.tableList = res.data.list;
            this.pagination = res.data.pagination;
            this.recordCurrent = res.data.pagination.current;
          }
        })
        .finally(() => {
          this.table_loading = false;
        });
    },
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getTableList(current, pageSize);
    },
    // 跳转创建编辑页
    createTag() {
      this.$router.push({
        name: "wechatMessage:create",
      });
    },
    deleteGroupRecord(id) {
      this.activeId = id;
      this.delBtnLoading = true;
      this.$api.core.GroupChat.deleteGroupRecord(id)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("成功删除群发记录！");
            this.getTableList(this.recordCurrent, 10);
          } else {
            this.$message.error(res.msg);
          }
        })
        .finally(() => {
          this.delBtnLoading = false;
        });
    },
    goSendMessage(id) {
      this.$router.push({
        path: "/wechatMessage/send",
        query: {
          id: id,
        },
      });
    },
    gotoEditGroup(value) {
      console.log("didididi", value[0].window_id);
      let id = value[0].window_id;
      this.$router.push({
        path: "/wechatMessage/create",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.nav_box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
.group_list {
  max-width: 460px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>